import _ from 'lodash';
import {getOptionsFromXLSX} from "./formHelper";

export const deckungOptions = [
	{
		value: 'gut',
		label: 'Gut'
	},
	{
		value: 'besser',
		label: 'Besser'
	},
	{
		value: 'riesig',
		label: 'Riesig'
	},
];

//  [MARRIED, SEPARATED_LIVING, SINGLE, WIDOWED, COHABITATION, REGISTERED_LIFE_PARTNERSHIP, MISCELLANEOUS, DIVORCED]
//  [SINGLE, SINGLE_PARENT, COUPLES, FAMILY];
export const familienstandOptions = [
	{
		value: 'single',
		label: 'Single'
	},
	{
		value: 'paar',
		label: 'Paar'
	},
	{
		value: 'alleinerziehend',
		label: 'Alleinerziehend'
	},
	{
		value: 'familie',
		label: 'Familie'
	},
];

export const selbstbehaltOptions_Privathaftpflicht = [
	{
		value: 0,
		label: '0 €'
	},
	{
		value: 150,
		label: '150 €'
	}
]

export const selbstbehaltOptions_Rechtsschutz = [
	{
		value: 0,
		label: '0 €'
	},
	{
		value: 150,
		label: '150 €'
	},
	{
		value: 300,
		label: '300 €'
	},
	{
		value: 500,
		label: '500 €'
	}
];
export const selbstbehaltOptions = [
	{
		value: 0,
		label: '0 €'
	},
	{
		value: 150,
		label: '150 €'
	},
	{
		value: 300,
		label: '300 €'
	},
	{
		value: 500,
		label: '500 €'
	},
	{
		value: 1000,
		label: '1000 €'
	},
];

export const anredeOptions = [
	{
		value: 'Herr',
		label: 'Herr'
	},
	{
		value: 'Frau',
		label: 'Frau'
	},
];

export const vorschaedenOptions = [
	{
		value: 0,
		label: 0
	},
	{
		value: 1,
		label: 1
	},
	{
		value: 2,
		label: 2
	},
];

export const vorschaedenOptions_Rechtsschutz = [
	{
		value: 0,
		label: 0
	},
	{
		value: 1,
		label: 1
	},
	{
		value: 2,
		label: 2
	},
	{
		value: 3,
		label: '3 oder mehr'
	},
];


export const schadenfreieJahre_Rechtsschutz = [
	{
		value: 0,
		label: 0
	},
	{
		value: 1,
		label: 1
	},
	{
		value: 2,
		label: 2
	},
	{
		value: 3,
		label: 3
	},
	{
		value: 4,
		label: 4
	},
	{
		value: 5,
		label: '5 oder mehr'
	},
];


export const zahlweiseOptions = [
	{
		value: 'jaehrlich',
		label: 'jährlich'
	},
	{
		value: 'halbjaehrlich',
		label: 'halbjährlich'
	},
	{
		value: 'vierteljaehrlich',
		label: 'vierteljährlich'
	},
	{
		value: 'monatlich',
		label: 'monatlich'
	},
];

export const zahlweiseOptions_Rechtsschutz = [
	{
		value: 'jaehrlich',
		label: 'Jährlich'
	},
	{
		value: 'monatlich',
		label: 'Monatlich'
	},
]
export const versicherungsDauerOptions = [
	{
		value: "1",
		label: "1 Jahr"
	},
	{
		value: "3",
		label: "3 Jahre"
	}
]

export const berufsgruppeRadioOptions = [
	{
		value: 'freie_wirtschaft',
		label: 'Freie Wirtschaft'
	},
	{
		value: 'oeffentlicher_dienst',
		label: 'Öffentlicher Dienst'
	}
];

export const JaNeinRadioOptions = [
	{
		value: 'ja',
		label: "Ja"
	},
	{
		value: 'nein',
		label: "Nein"
	},
];
export const JaNeinSwitcher = [
	{
		value: true,
		label: "Ja"
	},
	{
		value: false,
		label: "Nein"
	},
];

export const gekundigtDurchRadioOptions = [
	{
		value: "versicherungsnehmer",
		label: "Versicherungsnehmer"
	},
	{
		value: "versicherer",
		label: "Versicherer"
	}
];


export const accidentHouseholdOptions = [
	{
		value: 'mich',
		label: 'Mich'
	},
	{
		value: 'mich_und_partner',
		label: 'Mich und Partner'
	},
	{
		value: 'mich_und_kind(er)',
		label: 'Mich und Kind(er)'
	},
	{
		value: 'mich_partner_und_kind(er)',
		label: 'Mich, Partner und Kind(er)'
	},
	{
		value: 'kind(er)',
		label: 'Kind(er)',
		// disabled: true
	},
];

export const tariffAccidentOptions = [
	{
		value: 'gut',
		label: 'Gut'
	},
	{
		value: 'besser',
		label: 'Besser'
	},
	{
		value: 'riesig',
		label: 'Riesig'
	},
];

export const tariffOptions = [
	{
		value: 'L',
		label: 'Gut'
	},
	{
		value: 'XL',
		label: 'Besser'
	},
	{
		value: 'XXL',
		label: 'Riesig'
	},
	{
		value: 'XXXL',
		label: 'Riesig Plus'
	}
]

export const deckungHausholdOptions = [
	{
		value: 'XL',
		label: 'XL'
	},
	{
		value: 'XXL',
		label: 'XXL'
	},
];

export const deckungLegalCostsOptions = [
	{
		value: 'L',
		label: 'L'
	},
	{
		value: 'XL',
		label: 'XL'
	},
];

export const gebaeudeartOptions_Hausrat = [
	{
		value: 'einfamilienhaus',
		label: 'Einfamilienhaus'
	},
	{
		value: 'zweifamilienhaus',
		label: 'Zweifamilienhaus'
	},
	{
		value: 'mehrfamilienhaus',
		label: 'Mehrfamilienhaus'
	},
	{
		value: 'reihenhaus',
		label: 'Reihenhaus'
	},
];

export const gebaeudeartOptions_Wohngebaeude = [
	{
		value: 'einfamilienhaus',
		label: 'Einfamilienhaus'
	},
	{
		value: 'zweifamilienhaus',
		label: 'Zweifamilienhaus'
	},
	{
		value: 'mehrfamilienhaus',
		label: 'Mehrfamilienhaus'
	},
	{
		value: 'doppelhaushälfte',
		label: 'Doppelhaushälfte'
	},
	{
		value: 'doppelhaus',
		label: 'Doppelhaus'
	},
	{
		value: 'reihenhaus',
		label: 'Reihenhaus'
	}
]

export const bauartklasseOptions_Wohngebaeude = [
	{
		value: 1,
		label: 'BAK 1'
	},
	{
		value: 2,
		label: 'BAK 2'
	},
	{
		value: 3,
		label: 'BAK 3'
	}
]

export const gebaeudenutzungOptions_Wohngebaeude = [
	{
		value: 'ausschliesslich_privat',
		label: 'ausschließlich privat'
	},
	{
		value: 'auch_gewerblich',
		label: 'auch gewerblich'
	},
	{
		value: 'ferienhaus',
		label: 'Ferienhaus'
	}
]

export const selbstbehaltOptions_Wohngebaeude = [
	{
		value: 0,
		label: '0 €'
	},
	{
		value: 150,
		label: '150 €'
	},
	{
		value: 250,
		label: '250 €'
	},

	{
		value: 300,
		label: '300 €'
	},
	{
		value: 500,
		label: '500 €'
	},
	{
		value: 1000,
		label: '1000 €'
	},
	{
		value: 1500,
		label: '1500 €'
	},
	{
		value: 2000,
		label: '2000 €'
	},
];

export const elementarSchaedenOptions_Wohngebaeude = [
	{
		value: 0,
		label: 'kein Vorschaden'
	},
	{
		value: 1,
		label: '1 oder mehr Vorschäden'
	},
]

export const vorschaedenOptions_Wohngebaeude = [
	{
		value: 0,
		label: 'kein Vorschaden'
	},
	{
		value: 1,
		label: '1 Vorschaden'
	},
	{
		value: 2,
		label: '2 Vorschäden'
	},
	{
		value: 3,
		label: '3 oder mehr Vorschäden'
	},
]

export const dachausbauOptions_Wohngebaeude = [
	{
		value: 'ausgebaut',
		label: 'ausgebaut'
	},
	//	{
	//		value: null,
	//		label: 'teilweise ausgebaut'
	//	},
	{
		value: 'nicht_ausgebaut',
		label: 'nicht ausgebaut'
	},
	{
		value: 'flachdach',
		label: 'Flachdach'
	}
]

export const nutzungsartOptions_Wohngebaeude = [
	{
		value: 'eigennutzung',
		label: 'Eigennutzung'
	},
	{
		value: 'vermietet',
		label: 'vermietet'
	}
]

export const invalidityProgressionOptions = [
	{
		value: 100,
		label: '100%'
	},
	{
		value: 225,
		label: '225%'
	},
	{
		value: 350,
		label: '350%'
	},
	{
		value: 500,
		label: '500%'
	},
];

export const beginn_unfall_tagegeld_options = [
	{
		value: 1,
		label: '1. Tag'
	},
	{
		value: 43,
		label: '43. Tag'
	}
]


export const berufsgruppeOptions_Rechtsschutz = [
	{
		value: "arbeitnehmer",
		label: "Arbeitnehmer"
	},
	{
		value: "oeffentlicher_dienst",
		label: "Öffentlicher Dienst"
	},
	{
		value: "rentner",
		label: "Rentner"
	},
	{
		value: "selbststaendig",
		label: "Selbstständig/Freiberuflich"
	},
	{
		value: "student",
		label: "Azubi/Student"
	},
	{
		value: "ohne_berufliche_taetigkeit",
		label: "Ohne berufliche Tätigkeit"
	}
];

export const typenOptions_Fahrradversicherung = [
	{
		value: "PEDELEC",
		label: "E-Bike (nicht versicherungspflichtig)"
	}
];
export const nutzungOptions_Fahrradversicherung = [
	{
		value: "PRIVAT",
		label: "privat"
	},
	{
		value: "PRIVAT_GEWERBE",
		label: "privat & gewerblich"
	},
	{
		value: "GEWERBE",
		label: "gewerblich"
	}
];
export const conditionOptions_Fahrradversicherung = [
	{
		value: "NEU",
		label: "Neu"
	},
	{
		value: "GEBRAUCHT",
		label: "Gebraucht"
	}
];

export const produktOptions_Rechtsschutz = [
	{
		label: "Privat",
		value: "P"
	},
	{
		label: "Privat + Beruf",
		value: "PB"
	},
	{
		label: "Privat + Verkehr",
		value: "PV"
	},
	{
		label: "Privat + Beruf + Verkehr",
		value: "PBV"
	},
	{
		label: "Privat + Wohnen",
		value: "P+Wohn"
	},
	{
		label: "Privat + Beruf + Wohnen",
		value: "PB+Wohn"
	},
	{
		label: "Privat + Verkehr + Wohnen",
		value: "PV+Wohn"
	},
	{
		label: "Privat + Beruf + Verkehr + Wohnen",
		value: "PBV+Wohn"
	},
	{
		label: "Privat + Beruf + Verkehr + Vermieten",
		value: "PBV+Vermiet"
	},
	{
		label: "Privat + Beruf + Verkehr + Wohnen + Vermieten",
		value: "PBV+Wohn+Vermiet"
	},
	{
		label: "Verkehr",
		value: "V"
	},
	{
		label: "Verkehr + Wohnen",
		value: "V+Wohn"
	}
];


export const pensionSumInsuredOptions = _.range(0, 16, 1).map(i => {
	return {
		value: parseInt(i * 100),
		label: (i === 0) ? 'Nein' : `${(i * 100).toLocaleString('DE')}€`
	}
});

export const pensionSumInsuredChildOptions = _.range(0, 16, 1).map(i => {
	return {
		value: parseInt(i * 100),
		label: (i === 0) ? 'Nein' : `${(i * 100).toLocaleString('DE')}€`
	}
});

export const dailyHospitalBenefitSumInsuredOptions = _.range(0, 151, 5).map(i => {
	return {
		value: parseInt(i),
		label: (i === 0) ? 'Nein' : `${i}€`
	}
});

export const deathSumInsuredOptions = _.range(0, 101, 1).map(i => {
	return {
		value: parseInt(i * 1000),
		label: (i === 0) ? 'Nein' : `${i}.000€`
	}
});

export const plasterMoneyBlockInsuredOptions = [
	{
		label: "kein Gipsgeld",
		value: "0"
	},
	{
		label: "150 EUR",
		value: "150"
	},
	{
		label: "250 EUR",
		value: "250"
	},
	{
		label: "500 EUR",
		value: "500"
	}
];

export const deathSumInsuredChildOptions = _.range(0, 21, 1).map(i => {
	return {
		value: parseInt(i * 1000),
		label: (i === 0) ? 'Nein' : `${i}.000€`
	}
});
export const plasterMoneyBlockInsuredChildOptions = [
	{
		label: "kein Gipsgeld",
		value: "0"
	},
	{
		label: "150 EUR",
		value: "150"
	},
	{
		label: "250 EUR",
		value: "250"
	},
	{
		label: "500 EUR",
		value: "500"
	}
];


export const bikeHerstellerOptions_Fahrradversicherung = getOptionsFromXLSX('BIKE_Hersteller.xlsx', 1, 1)
export const bikeTypenOptions_Fahrradversicherung = getOptionsFromXLSX('BIKE_Typen.xlsx', 1, 1)

export const transitionalBenefitSumInsuredOptions = _.range(0, 31, 1).map(i => {
	return {
		value: parseInt(i * 1000),
		label: (i === 0) ? 'Nein' : `${i}.000€`
	}
});

export const sicknessDailyAllowanceSumInsuredOptions = _.range(0, 11, 1).map(i => {
	return {
		value: parseInt(i * 5),
		label: (i === 0) ? 'Nein' : `${i * 5}€`
	}
});

export const invaliditySumInsuredOptions = [
	{value: 0, label: 'Nein'},
	..._.range(20, 1001, 1).map(i => {
		return {
			value: parseInt(i * 1000),
			label: `${(i * 1000).toLocaleString('DE')}€`
		}
	})
];
