import {Button, Col, Form, Spinner} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo} from "react";
import Row from "react-bootstrap/Row";
import BerechnenResult from "../BerechnenResult";
import {tariffOptions} from "../../utils/optionsSelection";

const CalculationWrapperExtensions_PL = (props) => {
	const {values, resultRIESIG, resultBESSER, resultRIESIGPlus, goNextStep, calcLoading, chooseTarif} = props
	const [plusEinschluss, setPlusEinschluss] = React.useState(false)

	const result = useMemo(() => {
		switch (values.tarif) {
			case "XL": return resultBESSER
			case "XXL": return resultRIESIG
			case "XXXL": return resultRIESIGPlus
		}
	}, [values.tarif, resultBESSER, resultRIESIG, resultRIESIGPlus])

	useEffect(() => {
		if(values.tarif !== 'XXXL') { setPlusEinschluss(false) }
	},[values.tarif, setPlusEinschluss])

	const handlePlusEinschluss = useCallback((e) => {
		const checked = e.target?.checked

		if (checked) { chooseTarif('XXXL') }
		if (!checked) { chooseTarif('XXL') }

		setPlusEinschluss(checked)
	},[chooseTarif, setPlusEinschluss])

	return (
			<div>
				{(values.tarif === 'XXL' || values.tarif === 'XXXL') && <>
					<hr style={{backgroundColor: '#fff', marginTop: '30px'}}/>
					<h3 style={{textAlign: 'center', marginBottom: '30px'}}>Auswählbare Bausteine:</h3>
					<Row className={'calculationWrapperRow'}>
						<Col sm={12}className={`calculationWrapperCell`}>
							<div className={'calculationWrapperBorder'}>
								<Row>
									<Col sm={1} style={{display: "flex", justifyContent: "center"}}>
										<Form.Check
												type="switch"
												id={"switcher_id"}
												style={{backgroundColor:'transparent', border:'none', zIndex: 1}}
												className={'zusatzBausteinRadio'}
												value={plusEinschluss}
												checked={plusEinschluss}
												onChange={handlePlusEinschluss}
										/>
									</Col>
									<Col sm={10}><h5 className={'zusatzBausteinHead'}>Plus</h5></Col>
								</Row>
								<div className={'calculationWrapperBody'}>
									<ul className={'calculationWrapper'}>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Glasschäden an gemieteten Wohnräumen, Balkonen, Loggien und Terrassen</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Sachschäden mitversicherter Personen untereinander</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Personenschäden mitversicherter Personen untereinander</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Ausgleich zur Zeitwertregelung bei Beschädigung eigener Sachen, wenn ein anderer Haftpflichtversicherer den Zeitwert geleistet hat</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Beschädigung oder Zerstörung von gemieteten E-Scootern</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
				</>}
				<hr style={{backgroundColor: '#fff', marginTop: '30px'}}/>

				<div style={{margin: "30px", display: "flex", justifyContent: "center", alignItems: "center"}}>
					{calcLoading ?
						<Spinner animation="border" variant="info"/> :
						<>
							{
									result && <BerechnenResult error={result.calculationError}
																		result={result.calculationResult.grossPremium}
																		messages={result.calculationMessages}
																		zahlungsweise={values.zahlungsweise}
											 />
							}
							<Button
									style={{marginLeft: "20px"}}
									disabled={!result || result.calculationResult.grossPremium === 'NaN'}
									onClick={() => goNextStep(values.tarif, result.calculationResult)}
									className="btn btn-weiter btn-weiter-values.tarif"
							>
								Weiter mit Tarif {tariffOptions.find(el => el.value === values.tarif)?.label}
							</Button>
						</>
					}
				</div>
			</div>
	)
}

export default CalculationWrapperExtensions_PL
