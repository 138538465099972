import React from "react";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import CalculationWrapperExtensions_PL from "./CalculationWrapperExtensions_PL";

const CalculationWrapper_PL = (props) => {

	const {values, resultBESSER, resultRIESIG, resultRIESIGPlus, calcLoading, goNextStep, config, setFieldValue} = props;
	const placement = 'top'

	const chooseTarif = (deckung) => {
		setFieldValue('tarif', deckung);
	}

	return (
			<div>
				<Row className={'calculationWrapperRow'}>
					<Col sm={12} md={6} className={`calculationWrapperCell ${config && (config?.markBox === "XL" ? "markBox"
							: "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
							<span className={'calculationWrapperHead'}>Besser</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Haftpflicht-Schäden bis zu <b>15 Mio.
										€</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Schäden aus Internetnutzung <b>außerhalb
										Europas</b>
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>
										<OverlayTrigger
												placement={placement}
												overlay={
													<Tooltip id={'tooltip-gibsgeld'}>
														Künftige Tarifverbesserungen greifen automatisch auch für bestehende Verträge.
													</Tooltip>
												}
										>
											<span className={'calculationTooltip'}>Update-Garantie</span>
										</OverlayTrigger>
									</li>
								</ul>
								<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
									<li><span className={'ar-icon ar-icon-cross'}/>Motorisierte Flugmodelle/-drohnen bis 5 kg
									</li>
									<li><span className={'ar-icon ar-icon-cross'}/>
										<OverlayTrigger
												placement={placement}
												overlay={
													<Tooltip id={'tooltip-gibsgeld'}>
														Sollte der Schaden nicht in unserem Tarif
														versichert sein, zahlen wir trotzdem - wenn ein anderer Versicherungsanbieter
														am
														deutschen
														Markt diesen Schaden standardmäßig versichert.
													</Tooltip>
												}
										>
											<span className={'calculationTooltip'}>Best-Leistungs-Garantie</span>
										</OverlayTrigger>
									</li>
								</ul>

								<Button
										style={{width: '100%'}}
										onClick={() => chooseTarif("XL")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
					<Col sm={12} md={6} className={`calculationWrapperCell ${config && (config?.markBox === "XXL" ? "markBox"
							: "markDisabled")}`}>
						<div className={'calculationWrapperBorder'}>
							<span className={'calculationWrapperHead'}>Riesig</span>
							<div className={'calculationWrapperBody'}>
								<ul className={'calculationWrapper flexGrow'}>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Haftpflicht-Schäden bis zu <b>50 Mio.
										€</b></li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Schäden aus Internetnutzung <b>außerhalb
										Europas</b>
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Täglich kündbar</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>
										<OverlayTrigger
												placement={placement}
												overlay={
													<Tooltip id={'tooltip-gibsgeld'}>
														Künftige Tarifverbesserungen greifen automatisch auch für bestehende Verträge.
													</Tooltip>
												}
										>
											<span className={'calculationTooltip'}>Update-Garantie</span>
										</OverlayTrigger>
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>Motorisierte Flugmodelle/-drohnen bis 5 kg
									</li>
									<li><span className={'ar-icon ar-icon-checkmark'}/>
										<OverlayTrigger
												placement={placement}
												overlay={
													<Tooltip id={'tooltip-gibsgeld'}>
														Sollte der Schaden nicht in unserem Tarif
														versichert sein, zahlen wir trotzdem - wenn ein anderer Versicherungsanbieter
														am
														deutschen
														Markt diesen Schaden standardmäßig versichert.
													</Tooltip>
												}
										>
											<span className={'calculationTooltip'}>Best-Leistungs-Garantie</span>
										</OverlayTrigger>
									</li>
								</ul>

								<Button
										style={{width: '100%', marginTop: "auto"}}
										onClick={() => chooseTarif("XXL")}
										className="btn btn-weiter btn-weiter-tarif"
								>
									Tarif auswählen
								</Button>
							</div>
						</div>
					</Col>
				</Row>

				{values.tarif && <CalculationWrapperExtensions_PL
						values={values}
						resultBESSER={resultBESSER}
						resultRIESIG={resultRIESIG}
						resultRIESIGPlus={resultRIESIGPlus}
						chooseTarif={chooseTarif}
						calcLoading={calcLoading}
						goNextStep={goNextStep}
				/>}
			</div>
	)
}

export default CalculationWrapper_PL;
