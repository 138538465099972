import {Button, Col, Spinner} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import Row from "react-bootstrap/Row";
import RadioGroupInput from "../formitems/RadioGroupInput";
import BerechnenResult from "../BerechnenResult";
import {tariffOptions} from "../../utils/optionsSelection";

const CalculationWrapperExtensions_FV = (props) => {
	const {values, resultRIESIG, resultBESSER, goNextStep, calcLoading} = props

	const [reparatur, setReparatur] = useState(false);

	const result = useMemo(() => {
		switch (values.tarif) {
			case "XL":
				return resultBESSER
			case "XXL":
				return resultRIESIG
		}
	}, [values.tarif, resultBESSER, resultRIESIG])


	useEffect(() => {

		values['zusatzbausteinDiebstahl'] = true

	}, [values])

	useEffect(() => {

		const today = new Date()
		today.setHours(1, 0, 0, 0); // Für isoString auf 1 Uhr setzen, da es eine Stunde abzieht

		const dateBeforeThreeYears = new Date(today)
		dateBeforeThreeYears.setFullYear(today.getFullYear() - 3);

		const reDatum = new Date(values["versichertesfahrrad"]["kaufdatum"])

		if(reDatum){
			reDatum.setHours(2, 0, 0, 0); // Für isoString auf 2 Stunden höher zaehlen
			setReparatur(true)
			if(reDatum.toISOString() < dateBeforeThreeYears.toISOString()){
				setReparatur(false)
				values['zusatzbausteinReparatur'] = false
			}
		}

	}, [values, setReparatur])

	let istBausteinGewaehlt = (values['zusatzbausteinDiebstahl'] === true || values['zusatzbausteinReparatur'] === true || values['zusatzbausteinPannenhilfe'] === true)

	return (
			<div>
				<hr style={{backgroundColor: '#fff', marginTop: '30px'}}/>
				<h3 style={{textAlign: 'center', marginBottom: '30px'}}>Bausteine:</h3>


					<Row className={'calculationWrapperRow zusaetzlicheBausteineRow'}>
						<Col sm={12} md={reparatur?4:6} className={`calculationWrapperCell markBox`}>
							<div className={`calculationWrapperBorder `}>
								<Row>
									<Col sm={1} style={{display: "flex", justifyContent: "center"}}>
										<RadioGroupInput switcher item={'zusatzbausteinDiebstahl'}
															  className={'zusatzBausteinRadio'}
															  disabled={true}
															  overwriteDefaultStyle={{border: "none", zIndex: 1}}/>
									</Col>
									<Col sm={10}><h5 className={'zusatzBausteinHead'}>Diebstahl</h5></Col>
								</Row>
								<div className={'calculationWrapperBody'}>
									<ul className={'calculationWrapper'}>
										<li><span className={'ar-icon ar-icon-checkmark'}/><b>Weltweiter</b> Geltungsbereich</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Einfacher Diebstahl</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Einbruchdiebstahl</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Trickdiebstahl</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Plünderung</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Raub</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Fahrradanhänger und Zubehör <b>bis
											1.000
											€</b></li>
									</ul>
									<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
										<li><span className={'ar-icon ar-icon-cross'}/>Mitgeführtes Gepäck bis <b>2.000 €</b></li>
									</ul>
								</div>
							</div>
						</Col>
						{ reparatur && <Col sm={12} md={4} className={`calculationWrapperCell markBox`}>
							<div className={`calculationWrapperBorder`}>
								<Row>
									<Col sm={1} style={{display: "flex", justifyContent: "center"}}>
										<RadioGroupInput switcher item={'zusatzbausteinReparatur'}
															  className={'zusatzBausteinRadio'}
															  overwriteDefaultStyle={{border: "none", zIndex: 1}}/>
									</Col>
									<Col sm={10}><h5 className={'zusatzBausteinHead'}>Reparatur</h5></Col>
								</Row>
								<div className={'calculationWrapperBody'}>

									<ul className={'calculationWrapper'}>
										<li><span className={'ar-icon ar-icon-checkmark'}/><b>Weltweiter</b> Geltungsbereich</li>

										<li><span className={'ar-icon ar-icon-checkmark'}/>Verschleiß am E-Bike und E-Bike-Teilen
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Verschleiß Akku, sofern Minderung der
											Leistung von mind. 50 %
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/><b>6 Monate</b> Wartezeit bei
											Inanspruchnahme
											Verschleiß (Bremsen, Räder, Akku)
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Unfallschäden inkl. Fall- &
											Sturzschäden
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Vandalismus</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Brand, Blitzschlag, Explosion, Sturm,
											Hagel,
											Überschwemmungen, Lawinen, Erdrutsch
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Bedienfehler & unsachgemäße Behandlung
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Elektronik- & Feuchtigkeitsschäden</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Kabelbruch, Tierbisse</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Fahrradanhänger & Zubehör <b>bis
											1.000 €</b>
										</li>
									</ul>
									<ul className={'calculationWrapper calculationWrapperDisabled flexGrow'}>
										<li><span className={'ar-icon ar-icon-cross'}/>Mitgeführtes Gepäck bis <b>2.000 €</b></li>
									</ul>

								</div>
							</div>
						</Col>}
						<Col sm={12} md={reparatur?4:6}  className={`calculationWrapperCell markBox`}>
							<div className={`calculationWrapperBorder`}>
								<Row>
									<Col sm={1} style={{display: "flex", justifyContent: "center"}}>
										<RadioGroupInput switcher item={'zusatzbausteinPannenhilfe'}
															  className={'zusatzBausteinRadio'}
															  overwriteDefaultStyle={{border: "none", zIndex: 1}}/>
									</Col>
									<Col sm={10}><h5 className={'zusatzBausteinHead'}>Pannenhilfe</h5></Col>
								</Row>
								<div className={'calculationWrapperBody'}>
									<ul className={'calculationWrapper flexGrow'}>
										<li><span className={'ar-icon ar-icon-checkmark'}/><b>Europaweiter</b> Geltungsbereich
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Pannenhilfe vor Ort</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>E-Bike-Abtransport & -Rücktransport
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Weiter- oder Rückfahrt versicherter
											Personen <b>bis 500 €</b></li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Ersatzrad (bis 14 Tage, max. 50 € am Tag)
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Übernachtungskosten (bis max. 5 Nächte,
											max.
											80 € pro Nacht)
										</li>
										<li><span className={'ar-icon ar-icon-checkmark'}/>Bergung (1.000 €)</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>

				<div style={{margin: "30px", display: "flex", justifyContent: "center", alignItems: "center"}}>
					{calcLoading ?
							<Spinner animation="border" variant="info"/> :
							<>
								{ istBausteinGewaehlt ?
										<>
											{
													result && <BerechnenResult error={result.calculationError}
																						result={result.calculationResult.grossPremium}
																						messages={result.calculationMessages}
																						zahlungsweise={values.zahlungsweise}
															 />
											}
											<Button
													style={{marginLeft: "20px"}}
													disabled={!result || result.calculationResult.grossPremium === 'NaN'}
													onClick={() => goNextStep(values.tarif, result.calculationResult)}
													className="btn btn-weiter btn-weiter-values.tarif"
											>
												Weiter mit Tarif {tariffOptions.find(el => el.value === values.tarif)?.label}
											</Button>

										</>
										:
										<>
											<Button
													style={{marginLeft: "20px"}}
													disabled={true}
													className="btn btn-weiter btn-weiter-values.tarif"
											>
												Bitte erst Baustein wählen
											</Button>
										</> }

							</>
					}
				</div>
			</div>
	)
}

export default CalculationWrapperExtensions_FV
